<template>
  <div class="flex min-h-screen items-center justify-center flex-col p-4">
    <div class="w-full md:w-1/3 p-8 shadow rounded" @keyup.enter="continueAs">
      <span class="flex justify-center p-4">
        <img alt="Vue logo" src="@/assets/logo.svg" class="h-24" />
      </span>
      <p class="my-4 has-text-grey is-size-7">
        You are logged in as <u>{{ client }}</u> which requires additional
        information to continue to our website.
      </p>
      <b-field
        label="Name"
        custom-class="is-size-7 has-text-grey"
        :type="$store.getters.hasErrors('name').type"
        :message="$store.getters.hasErrors('name').message"
      >
        <b-input size="is-medium" v-model="name" icon="account" />
      </b-field>
      <b-field
        label="Email Address"
        custom-class="is-size-7 has-text-grey"
        :type="$store.getters.hasErrors('email').type"
        :message="$store.getters.hasErrors('email').message"
      >
        <b-input size="is-medium" v-model="email" icon="at" />
      </b-field>
      <div class="buttons mt-4">
        <b-button
          size="is-medium"
          type="is-gold"
          expanded
          :loading="$store.getters.isLoading"
          @click="continueAs"
          >Continue</b-button
        >
        <b-button size="is-medium" type="is-light" expanded @click="logout"
          >Logout</b-button
        >
      </div>
      <p class="mt-4 is-size-7 has-text-grey">
        <!-- The personal information you're about to submit will be validated and used to create a local account under {{ client }}. -->
        MK Themed Attractions Philippines Inc. together with our partners, value
        the privacy of our customers and commits to protect the confidentiality
        of our user's information.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    name: "",
    email: ""
  }),
  computed: {
    client() {
      return this.$store.getters.activeUser.name || "";
    }
  },
  methods: {
    continueAs() {
      this.$store.dispatch("continueAs", {
        name: this.name,
        email: this.email
      });
    },
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>
